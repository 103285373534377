import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router";
import { useSelector } from 'react-redux';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Tooltip from '@material-ui/core/Tooltip';
import './style.css';
import QuizIcon from './assets/quiz_black_24dp.svg'
import { startPostSession, createAction, endSession, getTestSubmissions, confirmAttendence } from '../containers/application/redux_actions';
import { connectToANode, listenToChildAdd, updateData } from '../utilities/firebase/fb_rtdb';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ReactComponent as FullScreenPage } from '../components/ExternalTool/full_screen.svg';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';


export default ({ setEndSessionPopup, width, height, data, typPC, isFullScreen, engagementData, manageToolData, engagementStatus }) => {

    let [boardState, setBoardState] = useState({})
    const [redirect, setRedirect] = useState(false);
    const [confirmedAttendance, setConfirmedAttedance] = useState(false)
    let [loader, setLoader] = useState(false)
    const [isAttendanceConfirmedForSession, setIsAttendanceConfirmedForSession] = useState(false)
    const { user_token } = useSelector((state) => state.auth)
    const app = useSelector((state) => state.app);
    const { user, createdSession, assessment_start, jobDone, } = app || {}
    const sessionId = createdSession["id"] || createdSession["_id"];
    const coachId = createdSession['coachId'];
    let { actions, instruction, attendanceConfirmation, reasons, otpExpiry, jobAfterCompletion } = data || {};

    async function manualRefresh() {
        setLoader(true)
        await getTestSubmissions({ user_token, sessionId }).then((res) => setLoader(false))
    }


    useEffect(() => {
        if (sessionId) {
            connectToANode({
                refString: `test/live_session/${sessionId}/`,
                f: (x) => {
                    if (x && x?.Participants) setBoardState(x?.Participants)
                    if (x && x?.isAttendanceConfirmedForSession) setIsAttendanceConfirmedForSession(true)
                }
            });
        }
        startPostSession({ sessionId, user_token })

    }, [sessionId])

    const renderStudentNameList = () => {
        let tempArr = [];
        if (Object.keys(boardState || {}).length > 0) {
            Object.keys(boardState || {}).filter(uid => boardState?.[uid]).map((uid, index) => {
                let student = boardState[uid]?.attendence?.eventData || {}
                let color = '#3ACA0F'
                tempArr.push(<>
                    <Tooltip title={<h3 style={{ color: 'white' }}>{student?.username}</h3>} arrow key={student?.studentId} className='ir-student-name-list'>
                        <div style={{
                            display: 'flex', alignItems: 'center',
                            fontSize: 16, fontWeight: 400, color: '#0000008a', gap: '0.5rem'
                        }}>
                            {boardState[uid]?.assessment ? <CheckCircleIcon style={{ fill: color }} /> : <span style={{ width: '20px', height: '20px', borderRadius: '50%', background: 'white', border: '2px solid #0000008a' }}></span>}
                            <span style={{ textTransform: 'capitalize' }}>{student.username}</span>
                        </div>
                    </Tooltip>
                </>)
            })

            if (tempArr?.length % 5 !== 0) {
                let tempLen = tempArr?.length % 5;
                let diff = 5 - tempLen;
                if (tempLen) {
                    for (let i = 0; i < diff + 5; i++)
                        tempArr.push(<div key={'dummy div ' + i} className='ir-student-name-list'>&nbsp;</div>)
                }
            }
            return tempArr;
        }
        else
            return null;
    }



    if (attendanceConfirmation) {
        const renderList = Object.keys(boardState || {}).reduce((res, studentId, i) => {
            let stub = {
                ['S.No.']: i + 1 > 9 ? `${i + 1}.` : `0${i + 1}.`,
                Name: boardState?.[studentId]?.attendence?.eventData?.username,
                ['Is participant present in class?']: boardState?.[studentId]?.attendenceConfirmedByCoach == null ? null : boardState?.[studentId]?.attendenceConfirmedByCoach,
                'Assessment status': boardState?.[studentId]?.assessment ? 'COMPLETED' : 'IN PROGRESS',
                Reason: boardState?.[studentId]?.Reason,
                studentId
            }
            res = res.length == 0 ? [stub] : [...res, stub]
            return res
        }, [])

        const headCells = ['S.No.', 'Name', 'Is participant present in class?', 'Assessment status']


        const registerAtttendance = (sId, v) => {
            let updates = {}
            updates[`test/live_session/${sessionId}/Participants/${sId}/attendenceConfirmedByCoach`] = v;

            updates[`test/live_session/${sessionId}/Participants/${sId}/Reason`] = reasons[v];
            updateData(updates)
        }

        const assessmentStatus = Object.keys(boardState || {}).reduce((res, a) => {
            res['assessmentDone'] = boardState[a]?.assessment ? res['assessmentDone'] + 1 : res['assessmentDone']
            return res
        }, { totalStudents: Object.keys(boardState || {}).length, assessmentDone: 0 })

        const confirmation = (v) => {
            updateData({
                [`test/live_session/${sessionId}/isAttendanceConfirmedForSession`]: v,
                [`test/live_session/${sessionId}/attendanceConfirmedAt`]: new Date(),
            })

        }

        const __confirmAttendence = () => {
            setLoader(true)
            confirmAttendence({
                body: { users: renderList, sessionId, coachId, otpExpiry: otpExpiry || false, jobAfterCompletion: jobAfterCompletion || [], programId: user.programId },
                user_token
            })
                .then(res => {
                    confirmation(true);
                    setLoader(false);
                    setRedirect(true);
                })
        }

        const leftTobeMarked = Object.keys(boardState || {}).filter(uid => boardState[uid]?.attendenceConfirmedByCoach == null).length;
        const activeConfirm = leftTobeMarked == 0 && assessmentStatus.assessmentDone + assessmentStatus.totalStudents !== 0;

        if (!isFullScreen) return <FullScreenPage style={{ width: '100%', height: '100%' }} />
        if (isAttendanceConfirmedForSession || confirmedAttendance) {
            const InProgress = renderList.filter(r => r['Assessment status'] !== 'COMPLETED' || r['Is participant present in class?'] !== true).map(r => r);
            const InProgressParticipants = InProgress.reduce((res, r) => {
                res[r.Reason] = res?.[r.Reason] ? [...res?.[r.Reason], r] : [r]
                return res
            }, {});
            const providedReason = InProgress.filter(r => !r['Reason']).map(r => r);


            return <div style={{ display: 'flex', flexDirection: 'column', gap: "1.5rem", padding: "4rem 4rem 3rem", width, height, padding: !isAttendanceConfirmedForSession ? "9vh 7vw" : '' }}>
                <div style={{ fontSize: "1.3rem", display: "flex", alignItems: 'center', fontWeight: 700, }}><ArrowBackOutlinedIcon onClick={() => setConfirmedAttedance(false)} style={{ cursor: "pointer", margin: "0rem 2rem 0rem 0rem" }} />Confirm Submission</div>
                {InProgress?.length > 0 && !isAttendanceConfirmedForSession ?
                    <div style={{ color: "#E4001B", fontWeight: 700, fontSize: "1.2rem" }}>
                        Please confirm the status of the following participants. <br /> <span style={{ color: "#646464", fontSize: '1.1rem', fontWeight: 400 }}>Once you confirm & end session, the following participant(s) will NOT be able to complete their assessment.</span>
                    </div>
                    :
                    <div style={{ color: "#2FAE09", fontSize: "1.2rem", fontWeight: 700 }}>
                        All {assessmentStatus.assessmentDone} participants are present and have completed the assessment.
                    </div>
                }
                {InProgress?.length > 0 && !isAttendanceConfirmedForSession ?
                    <div style={{ display: "flex", flexDirection: "column", marginTop: "1rem", }}>
                        {Object.keys(InProgressParticipants || {}).map(r => {
                            return <div key={r}>
                                <div style={{ color: "#757575", fontWeight: 700, borderBottom: "1px solid #D4D4D4", textTransform: "uppercase", paddingBottom: "1rem", fontSize: "1.1rem" }}>{r} {r !== 'Absent, not eligible for certificate' ? <Button style={{ border: "1px solid #BBBBBB", padding: "5px 10px", borderRadius: 10, marginLeft: "2rem", color: "#949494", fontSize: "0.7rem", fontWeight: 500 }}>REFRESH STATUS <RefreshIcon style={{ cursor: "pointer", marginLeft: '1rem' }} onClick={() => manualRefresh()} /></Button> : null}
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', padding: !isFullScreen ? "1rem 0rem" : '1rem', maxHeight: '16vh', overflow: "scroll", gridAutoRows: "max-content", gridRowGap: "1rem" }}>
                                    {InProgressParticipants[r].map(s => {
                                        return <div key={s.studentId} style={{ display: 'flex', fontWeight: 400, alignItems: 'center' }}><AccountCircleOutlinedIcon style={{ marginRight: '1rem' }} /> {s.Name}</div>
                                    })}
                                </div>
                            </div>
                        })}
                    </div> : null
                }
                <div style={{ display: "flex", gap: "2rem", alignItems: 'center' }}>
                    {createdSession['end'] ? <Button onClick={() => setConfirmedAttedance(false)} style={{ borderRadius: 12, background: "", padding: '0.5rem 4rem', border: "2px solid #000000", color: "#000000" }}>BACK</Button> : null}
                    <Button
                        disabled={providedReason?.length > 0}
                        onClick={() => confirmedAttendance ? __confirmAttendence() : setConfirmedAttedance(true)}
                        style={{ background: providedReason?.length == 0 ? "#E4001B" : '#ECECEC', padding: '0.5rem 4rem', borderRadius: 12, color: "#fff" }}>CONFIRM & END SESSION</Button>
                </div>
                {redirect === true && <Redirect to={'/application'} push={true} />}
                {loader && <CircularProgress style={{ position: 'absolute', left: '50%', top: '50%', }} />}
            </div >
        }

        return (
            <div style={{ width, height, background: '#fff' }}>
                <div className='w-100 h-100' style={{ padding: '4% 3%' }}>
                    <div className='h-35 overFlowYAuto tct-scrollable-darkgray'>
                        <div style={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}>
                            <div style={{ display: "flex", alignItems: 'center', gap: "3rem" }}>
                                <div style={{ display: 'flex', fontWeight: 'bold' }}><img src={QuizIcon} style={{ width: '2em', marginRight: '0.5em' }} />{data.title}</div>
                                <div style={{ border: '1px solid #C9C9C9', background: '#F4F2F2', borderRadius: 15, padding: "0.5rem 1.2rem", width: isFullScreen ? "" : '40%' }}>{typPC !== "Prepare" && app?.otpData?.otp && <span>Assessment Code: <span className='ir-post-session-otp' >{app?.otpData?.otp}</span></span>}</div>
                            </div>
                            <div>Assessment {!isFullScreen ? <br /> : ''} Completed <span style={{ borderRadius: 12, marginLeft: '0.5rem', border: '1px solid #D7D7D7', background: "#F8F8F8", padding: '0.5rem 1rem' }}>{assessmentStatus.assessmentDone}/{assessmentStatus.totalStudents}</span></div>
                        </div>
                    </div>
                    <div style={{ padding: "2rem 0rem 1rem", display: 'flex', justifyContent: "space-between", fontSize: "1rem" }} >
                        <p dangerouslySetInnerHTML={{ __html: data?.instructions.replace('STUDENTS', leftTobeMarked) }} />
                    </div>
                    <div>
                        <div style={{ fontSize: "20px", fontWeight: 700, padding: "0.8rem 1rem", borderTop: "1px solid #D4D4D4", borderBottom: "1px solid #D4D4D4", display: 'grid', gridTemplateColumns: '1fr 2fr 2.5fr 2fr' }}>
                            {headCells.map(k => {
                                return <div style={{ display: 'flex', alignItems: "center", fontSize: '0.9rem' }}>{k} {k == 'Assessment status' ? <RefreshIcon style={{ cursor: "pointer", margin: '0rem 2rem' }} onClick={() => manualRefresh()} /> : ''}</div>
                            })}
                        </div>
                        <div className='scrollDivNew' style={{ display: 'grid', gridTemplateColumns: '1fr 2fr 2.5fr 2fr', padding: !isFullScreen ? "1rem 0rem" : '1rem', height: '47vh', overflow: "scroll", gridAutoRows: "max-content", gridRowGap: "0.6rem" }}>
                            {renderList.map(r => {
                                return headCells.map(k => {
                                    if (k == 'Is participant present in class?') {
                                        return <div style={{ display: "flex", gap: "1rem" }}>
                                            <Button onClick={() => registerAtttendance(r['studentId'], true)} style={{ height: "max-content", fontSize: "0.9rem", fontWeight: 700, border: "1px solid #C0C0C0", color: r[k] == null ? '#929292' : r[k] == true ? 'white' : '#929292', background: r[k] == null ? 'white' : r[k] == true ? '#3ACA0F' : 'white' }}>PRESENT</Button>
                                            <Button onClick={() => registerAtttendance(r['studentId'], false)} style={{ height: "max-content", fontSize: "0.9rem", fontWeight: 700, border: "1px solid #C0C0C0", color: r[k] == null ? '#929292' : r[k] == false ? 'white' : '#929292', background: r[k] == null ? 'white' : r[k] == false ? '#E4001B' : 'white' }}>NOT PRESENT</Button>
                                        </div>
                                    }
                                    else if (k == 'Assessment status') {
                                        return <div style={{ height: "max-content", fontSize: "0.9rem", color: r[k] == 'COMPLETED' ? '#2FAE09' : '#E45400', fontWeight: 700 }}>{r[k]}</div>
                                    }
                                    return <div style={{ height: "max-content", fontSize: "1rem", fontWeight: 400 }}>{r[k]}</div>
                                })
                            })}
                        </div>
                    </div>
                    <div style={{ padding: "1rem", textAlign: "center", background: "#ECECEC", borderRadius: 12, fontSize: "1rem", fontWeight: 700, color: "#1F1F1F" }}>
                        I hereby declare that the above participants are physically present in the class. <Button disabled={!activeConfirm} onClick={() => setConfirmedAttedance(true)} style={{ borderRadius: 12, padding: "0.5rem 1rem ", background: activeConfirm ? "#3ACA0F" : "#a59c9c", cursor: activeConfirm ? "pointer" : "not-allowed", color: "white", marginLeft: "2rem", fontSize: '1rem' }}>SUBMIT</Button>
                    </div>
                </div>
            </div >
        );
    }

    return (
        <div style={{ width, height, background: '#EEEEEE' }}>
            <div className='w-100 h-100' style={{ padding: '0 2% 2%' }}>
                <div className='h-35 overFlowYAuto tct-scrollable-darkgray'>
                    <div className='post-session-title'>
                        <img src={QuizIcon} style={{ width: '2em', marginRight: '0.5em' }} />{data.title}
                    </div>
                    <div className='d-flex' style={{ margin: '1em 0' }}>
                        <div className='ir-post-session-assessment-code'>
                            {
                                typPC !== "Prepare" && app?.otpData?.otp && <span>Assessment Code: <span className='ir-post-session-otp' >{app?.otpData?.otp}</span></span>
                            }
                        </div>
                        <div className='ir-post-session-module-name'>
                            {data?.myAppModules?.map(item => <div key={item} className='post-session-module-names'>{item}</div>)}
                        </div>
                    </div>
                </div>
                <div className='ir-post-session-name-list' style={{ height: isFullScreen ? '65%' : '52%' }}>
                    <div style={{ fontSize: '0.8em', height: '15%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span >({(Object.keys(boardState || {}).filter(uid => boardState[uid]?.assessment)).length}/{(Object.keys(boardState || {}).filter(uid => boardState[uid]?.attendence)).length}) participants completed assessment</span>
                        {false && actions?.length > 0 && < div style={{ display: 'flex', fontSize: 14 }}>
                            <FormControlLabel
                                style={{ margin: 0 }}
                                classes={{
                                    label: 'ir-conduct-dialog-student-list-student-name'
                                }}
                                control={<Checkbox style={{ padding: '5px' }} color='default'
                                    checked={true}
                                    icon={<RadioButtonUncheckedIcon style={{ fontSize: 28 }} />} checkedIcon={<CheckCircleIcon style={{ color: '#ffbc36', fontSize: 28 }} />} />}
                                label={'Assessment Done'}
                            />
                            <FormControlLabel
                                style={{ margin: 0 }}
                                classes={{
                                    label: 'ir-conduct-dialog-student-list-student-name'
                                }}
                                control={<Checkbox style={{ padding: '5px' }} color='default'
                                    checked={true}
                                    icon={<RadioButtonUncheckedIcon style={{ fontSize: 28 }} />} checkedIcon={<CheckCircleIcon style={{ color: '#3ACA0F', fontSize: 28 }} />} />}
                                label={'Assessment and any one flashpoint done'}
                            />

                        </div>}

                        <div style={{ display: 'flex', fontSize: '16px', color: "#0000008a", alignItems: 'center', cursor: "pointer", fontSize: "11px" }}
                            onClick={() => manualRefresh()}>Manual Refresh <RefreshIcon />  </div>

                    </div>
                    <div className='d-flex flex-wrap overFlowYAuto tct-scrollable-darkgray h-85 align-content-baseline' style={{ position: 'relative' }}>
                        {renderStudentNameList()}
                        {loader && <CircularProgress style={{
                            position: 'absolute', left: '50%',
                            top: '50%',
                        }} />}
                    </div>
                </div>
                <div style={{ fontSize: isFullScreen ? '0.8em' : '0.7em', padding: '1em' }}>
                    {instruction ? instruction : 'Are there participants who could not finish the assessments? Drop their names and phone numbers in the help chat icon. Our support team will connect soon. (Note: Help icon is available on bottom right corner in exit full screen mode)'}
                </div>
            </div>
        </div >
    );

}