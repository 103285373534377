import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles_2 = makeStyles((theme) => ({
    paper: { position: "absolute", bottom: "4vh", margin: 0, left: "23vw", }, root: {
        // background: "black"
    }
}));


const FullScreenPop = ({ id, value }) => {

    const [open, setOpen] = React.useState(true)
    // setTimeout(() => {
    //     setOpen(false)
    // }, 3000)
    return (
        <Dialog

            aria-hidden={false}
            open={open}
            disableBackdropClick={false}
            TransitionComponent={Transition}
            onBackdropClick='false'
            keepMounted
            classes={{
                paper: useStyles_2().paper,
                root: useStyles_2().root,
            }}
            onClose={() => console.log('ji')}
        // container={() => document.getElementById(id)}
        >
            <div>Enter Fullscreen <ArrowForwardIosOutlinedIcon /></div>


        </Dialog>
    );
}

export default FullScreenPop