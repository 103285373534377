import { Button } from "@material-ui/core";
import { mdiCellphone, mdiEmail } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect } from "react";
import EmailPassword from "./EmailPassword";
import MobileOTP from "./MobileOTP";

export default ({ auth, app, mobileType, setMobileType }) => {
  return (
    <>
      <div
        className="ap-login-field-container tct-scrollable-darkgray overFlowYAuto"
        style={{ maxHeight: "85vh", padding: "8% 14%", backgroundColor: 'white', borderRadius: '8px', }}
      >
        {mobileType === "mobileOTP" ?
          <MobileOTP app={app} />
          // <div>hiih</div>
          :
          <EmailPassword />
        }
      </div>
      {!app?.loginOTP?.success && < div style={{ marginTop: "5%", padding: '0px 50px' }}>
        <div
          style={{
            textAlign: "center",
            fontSize: '16px', fontWeight: 400
          }}
        >
          or login using
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(1, 1fr)",
            gap: "5%",
          }}
        >
          <Button
            style={{
              width: "100%",
              margin: "12px 0px ",
              backgroundColor: "#CDD2D4 ",
              padding: " 12px ",
              borderRadius: "17px ",
              fontWeight: "400 ",
              fontFamily: "'tablet-gothic' ",
              fontSize: "1.25rem ",
              display: mobileType === "mobileOTP" ? "none" : "block",
            }}
            onClick={() => setMobileType("mobileOTP")}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Icon path={mdiCellphone} size={1} />{" "}
              <div style={{ margin: "-1.5% 0 0 2%" }}>Phone</div>
            </div>
          </Button>

          <Button
            style={{
              width: "100%",
              margin: "12px 0px ",
              backgroundColor: "#CDD2D4 ",
              padding: " 12px ",
              borderRadius: "17px ",
              fontWeight: "400 ",
              fontSize: "1.25rem ",
              display: mobileType === "emailPassword" ? "none" : "block",
            }}
            onClick={() => setMobileType("emailPassword")}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Icon path={mdiEmail} size={1} />{" "}
              <div style={{ margin: "-1.5% 0 0 2%" }}>Mail</div>
            </div>
          </Button>
        </div>
      </div>}
    </>
  );
};
