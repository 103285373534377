import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import Logout from "../auth/Logout";
import Register from "./Auth/Register";
import "./style.css";
import { useSelector } from "react-redux";
import PegogLogo from "./Auth/assets/pedgog-logo.svg";
import IllumineLogo from "./Auth/assets/illumine-logo.svg";
import Board from "./Auth/assets/board.svg";
import { Redirect } from "react-router";
import Login from "./Auth/Login";
import { Button } from "@material-ui/core";
import { mdiEmail, mdiGoogle, mdiCellphone } from "@mdi/js";
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import store from "../../Redux_Store";

export default ({ }) => {
  const { auth, app } = useSelector((state) => state);
  const [mobileType, setMobileType] = useState("mobileOTP");
  const [accesscodeData, setAccessCodeData] = useState();
  let [enterAccesscode, setEnterAccessCode] = useState(false)
  let p1 = window.location.pathname
  let register = p1.includes('register')

  const handleBackBtn = () => {
    if (!register) { store.dispatch({ type: 'RESET_LOGIN_OTP' }) }
    else if (enterAccesscode && accesscodeData?.accessCode) { setAccessCodeData(null) }
    else setEnterAccessCode(false)
  }

  return (
    <div className="main-container"
      style={!register ? {
        paddingLeft: mobileType === "mobileOTP" ? '20vw' : '16vw',
        paddingTop: mobileType === "mobileOTP" ? '10vh' : "5vh",
      } : {
        paddingLeft: '28vw',
        paddingTop: '3vh'
      }}
    >
      <div style={{ position: "absolute", bottom: "4%", left: "2%" }}>
        <img src={IllumineLogo} alt="IllumineLogo" style={{ width: "65px", height: '73px' }} />
        <div
          // className="ap-gray-color-1 ir-copyright"
          style={{ fontSize: "0.6rem", margin: "0", color: '#656565' }}
        >
          Copyright 2019-23; Illumine. All rights reserved.
        </div>
      </div>
      <div style={{ position: "absolute", top: "5vh", left: "3vw", }}>
        <img
          src={PegogLogo}
          style={{
            width: '7vw',
            height: '20vh'
          }}
          alt="pegogLogo"
        />
      </div>
      {
        !register ? app?.loginOTP?.success &&
          <div
            style={{ display: 'flex', alignItems: 'center', color: '#484848', fontWeight: 400, cursor: 'pointer', fontSize: '0.9rem' }}
            onClick={() => handleBackBtn()} >
            <ArrowBackIosOutlinedIcon style={{ marginRight: "1.5%", width: "0.7em", fontSize: '0.9rem' }} />Back
          </div> :
          enterAccesscode && <div
            style={{ display: 'flex', alignItems: 'center', color: '#484848', fontWeight: 400, cursor: 'pointer', fontSize: '0.9rem' }}
            onClick={() => handleBackBtn()} >
            <ArrowBackIosOutlinedIcon style={{ marginRight: "1.5%", width: "0.7em", fontSize: '0.9rem' }} />Back
          </div>
      }
      <div className='loginDiv'
        style={register ? {
          width: '45vw'
        } : {
          width: mobileType === "mobileOTP" ? '37vw' : '45vw'
        }}
      >
        <div
          className="ap-login-field-container tct-scrollable-darkgray overFlowYAuto"
        >
          <Switch>
            <Route
              path="/auth/login"
              render={() => (
                <Login
                  app={app}
                  auth={auth}
                  mobileType={mobileType}
                  setMobileType={setMobileType}
                />
              )}
            />

            <Route path="/logout" component={Logout} />
            <Route path="/auth/loginAsUser" render={() => (
              <>hii</>
            )} />
            <Route
              path="/auth/register"
              render={() =>
                <Register app={app} auth={auth}
                  accesscodeData={accesscodeData}
                  setAccessCodeData={setAccessCodeData}
                  enterAccesscode={enterAccesscode}
                  setEnterAccessCode={setEnterAccessCode}
                />}
            />
            {/* <Route path="/auth/forgotpassword" component={ForgotPassword} />
                            <Route path="/auth/resetpassword" component={ResetPassword} /> */}
            <Route
              path="/"
              render={() => (
                <Login
                  app={app}
                  auth={auth}
                  mobileType={mobileType}
                  setMobileType={setMobileType}
                />
              )}
            />
          </Switch >
        </div >

        {!register && <div className="ap-gray-color-1 text-center ir-copyright">
          By clicking on Login, I accept the{" "}
          <a
            href="https://illumine.in/terms-of-use.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms & Conditions
          </a>{" "}
          &{" "}
          <a
            href="https://illumine.in/privacy-policy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </div>}
      </div >
      {
        !register && <div style={{ display: 'flex', flexDirection: 'column', position: "absolute", top: "22vh", right: "3vw", width: '29vw', textAlign: "center", lineHeight: '26px', fontSize: '1.1rem' }}>   <img
          src={Board}
          style={{
            // width: '7vw',
            height: '34vh',
            // marginRight: "10%",
            // marginTop: "auto",
            marginBottom: "2%",
          }}
          alt="pegogLogo"
        />
          Now available as a new platform that provides you with unique coaching and interactive experience.
          {/* <div style={{ textAlign: 'center', fontWeight: 400, color: '#333333', height: '13vh', lineHeight: '18px' }}></div> */}
        </div>
      }

      {
        auth?.isLoggedIn && (
          <Redirect
            to={{
              pathname: "/application",
            }}
          />
        )
      }
    </div >
  );
};




